import logo from './assets/home_plus.png';
import './App.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@mui/material/Alert';
import * as React from 'react';
// import MuiAlert from '@material-ui/lab/Alert'; 


const useStyles = makeStyles((theme) => ({
  submitButton: {
    marginTop:'50%',
    float:'right',
    marginRight:'10%',
    color:'#0c2538'
  },
  hidden:{
    display:'none'
  },
  root: {
    width: '100%',
    marginTop:'2%',
    
    '& label.Mui-focused': {
      color: 'grey',
      borderColor:'grey'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        width:'auto'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'grey',
      },
    },
  },
}));


function App() {

  const [company, setCompany] = useState(null)
  const [surveyType, setSurveyType] = useState(null)

  // Form value state declarations
  const [customerName, setCustomerName] = useState(null)
  const [customerPhone, setCustomerPhone] = useState(null)
  const [customerEmail, setCustomerEmail] = useState(null)
  const [customerAddress, setCustomerAddress] = useState(null)
  const [customerPostal, setCustomerPostal] = useState(null)
  const [purchasePrice, setPurchasePrice] = useState(null)
  const [colleagueName, setColleagueName] = useState(null)
  const [branch, setBranch] = useState(null)
  const [branchPhone, setBranchPhone] = useState(null)
  const [otherCompany, setOtherCompany] = useState(null)
  const [furtherInformation, setFurtherInformation] = useState(null)
  const [open, setOpen] = useState(false)
  const [colleagueMail,setColleagueMail] = useState(null)

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  function submission() {
    if (customerName === null) {
        setCustomerName('')
    }
    if (customerPhone === null) {
        setCustomerPhone('')
    }
    if (colleagueMail == null){
      setColleagueMail('')
    }
    if (customerEmail === null) {
      setCustomerEmail('')
    }
    if (customerAddress === null) {
      setCustomerAddress('')
    }
    if (customerPostal === null) {
      setCustomerPostal('')
    }
    if (purchasePrice === null) {
      setPurchasePrice('')
    }
    if (surveyType === null) {
      setSurveyType('')
    }
    if (colleagueName === null) {
      setColleagueName('')
    }
    if (branch === null) {
      setBranch('')
    }
    if (branchPhone === null) {
      setBranchPhone('')
    }
    if (company === null) {
      setCompany('')
    }
    if (company === 'Other' && otherCompany===null) {
      setCompany('Other')
      setOtherCompany('')
    }
    if (
      customerName !== ''
      && colleagueMail !== ''
      && customerPhone !== ''
      && customerEmail !== ''
      && customerAddress !== ''
      && customerPostal !== ''
      && purchasePrice !== ''
      && surveyType !== ''
      && colleagueName !== ''
      && branch !== ''
      && branchPhone !== ''
      && company !== ''
      && otherCompany !== ''
    ){
      var lead = {
        'customerName':customerName,
        'customerPhone':customerPhone,
        'customerEmail':customerEmail,
        'customerAddress':customerAddress,
        'customerPostal':customerPostal,
        'purchasePrice':purchasePrice,
        'surveyType':surveyType,
        'colleagueName':colleagueName,
        'colleagueMail':colleagueMail,
        'branch':branch,
        'branchPhone':branchPhone,
        'company':company,
        'otherCompany':otherCompany,
        'furtherInfo':furtherInformation,
      }
      fetch('api/submit',{
        headers:{
          'Accept':'application/json',
          'Content-Type':'application/json'
        },
        method: 'POST',
        body: JSON.stringify(lead)
      }).then(resp => {
        // window.location.reload(false);
        setOpen(true) 
        setTimeout(function(){
          window.location.reload(false)
        },3000)
      })
      
    }

  };

  

  const handleCompany = (event) => {
    setCompany(event.target.value);
  };

  const handleSurveyType = (event) => {
    setSurveyType(event.target.value);
  }

  function getCompany(){
    if (company === null){
      return false
    }
    if ( (company == 'Other') || (company.indexOf('PRIMIS') === 0) || (company.indexOf('The Mortgage Alliance (TMA)') === 0)){
      return true;
    }
    else{
      return false
    }
  }

  const classes = useStyles();

  return (
    <div className=" w-70 h-[-webkit-fill-available]">
      <nav className='w-full h-95px bg-esurvblue'>
        <img className='h-auto w-10 lg:w-20 ml-5 pt-2 pb-2' src={logo}></img>
      </nav>
      <div className=' bg-white ml-[10%] mr-[10%] lg:mr-[20%] lg:ml-[20%] sm:ml-[15%] sm:mr-[15%] lg:pb-3 pt-3'>
        <h1 className="pl-3 text-lg lg:text-[35px] sm:text-[30px]">Refer a customer to HomePlus</h1>
        <div class="grid grid-cols-1 grid-rows-2 lg:grid-rows-1  lg:grid-cols-2 gap-4 lg:gap-4 pt-3">
          <div className='w-5/5 lg:w-4/5 ml-3 mr-3 pl-3 bg-white mb-3'>
            <p className='sm:text-[15px] text-[10px] lg:text-[20px]'>Customer details</p>
            <TextField
              label="Customer Name"
              id="customer_name"
              variant='outlined'
              className={classes.root}
              error={customerName===''} helperText={customerName===''?'Please provide a name':''} onChange={(e)=>setCustomerName(e.target.value)} 
            />
            <TextField
                error={customerPhone===''} 
                type='number' 
                helperText={customerPhone===''?'Please provide a phone number':''}
                onChange = {(e)=>setCustomerPhone(e.target.value)}
                id="standard-basic" InputProps={{className: classes.labelText}}
                className={classes.root}
                variant='outlined' 
                label="Customer phone no."
            />
            <TextField 
              error={customerEmail===''} 
              helperText={customerEmail===''?'Please provide an email':''}
              onChange = {(e)=>setCustomerEmail(e.target.value)}
              id="standard-basic" 
              className={classes.root} 
              label="Customer email address" 
              variant='outlined'  
            />

            <TextField 
              className={classes.root}
              id="standard-basic" 
              error={customerAddress===''} helperText={customerAddress===''?'Please provide an address':''}
              onChange = {(e)=>setCustomerAddress(e.target.value)}
              label="Purchase property address" 
              multiline
              variant='outlined'
            />
          
            <TextField 
              onChange = {(e)=>setCustomerPostal(e.target.value)}
              error={customerPostal===''} 
              helperText={customerPostal===''?'Please provide a postal code':''}id="standard-basic" 
              className={classes.root} 
              label="Purchase property postcode" 
              variant='outlined'
              multiline={false}
            />
            <TextField 
              onChange={(e)=>setPurchasePrice(e.target.value)}
              error={purchasePrice===''} 
              helperText={purchasePrice===''?'Please provide a purchase price':''}
              id="standard-basic" className={classes.root} 
              label="Purchase price" 
              variant='outlined'
            />
            <FormHelperText 
              error={surveyType===''}
              className={classes.root}>
              {surveyType!==''?'Survey type':'Please select survey type'}
            </FormHelperText>
            <Select 
              labelId="selectDropdown"
              id="selectDropdown"
              value={surveyType}
              onChange={handleSurveyType}
              className={classes.root}
              variant='outlined'
              >
                <MenuItem value={'HomePlus Digital Home Survey (L2)'}>HomePlus Digital Home Survey (L2)</MenuItem>
                <MenuItem value={'Valuation'}>Valuation</MenuItem>
                <MenuItem value={'Help to Buy'}>Help to Buy</MenuItem>
                <MenuItem value={'Level 1'}>Level 1</MenuItem>
                <MenuItem value={'Level 2'}>Level 2</MenuItem>
                <MenuItem value={'Level 3'}>Level 3</MenuItem>
            </Select>



          </div>
          <div className='w-5/5 lg:w-4/5 ml-3 mr-3 pl-3 bg-white'>
            <p className='sm:text-[15px] text-[10px] lg:text-[20px]'>Your Details</p>
            <TextField 
              onChange={(e)=>setColleagueName(e.target.value)}
              error={colleagueName===''} 
              helperText={colleagueName===''?'Please provide your name':''}
              id="standard-basic" 
              className={classes.root} 
              label="Your name"
              variant='outlined'
              />
            <TextField onChange={(e)=>setColleagueMail(e.target.value)}
              error={colleagueMail===''} 
              helperText={colleagueMail===''?'Please provide an email address':''}
              id="standard-basic" 
              className={classes.root} 
              label="Your email address" 
              variant='outlined'
            />
            <TextField 
              onChange={(e)=>setBranch(e.target.value)}
              id="standard-basic" 
              className={classes.root} 
              error={branch===''} 
              helperText={branch===''?'Please provide a branch name':''} 
              label="Branch" 
              variant='outlined'
            />
            <TextField type='number' onChange={(e)=>setBranchPhone(e.target.value)}
              error={branchPhone===''} 
              helperText={branchPhone===''?'Please provide a branch phone number':''}
              id="standard-basic" 
              className={classes.root} 
              label="Branch phone no." 
              variant='outlined'
            />  
            <FormHelperText error={company===''} 
              className={classes.labelContainer} 
              InputProps={{className: classes.root}} 

            >
              {company!==''?'Company':'Please select a company'}
            </FormHelperText>
            <Select 
              labelId="selectDropdown"
              id="selectDropdown"
              value={company}
              onChange={handleCompany}
              className={classes.root}
              error={company===''} helperText={company===''?'Please provide a company name':''}
              variant='outlined'
              >
                <MenuItem value={'Your Move'}>Your Move</MenuItem>
                <MenuItem value={'Reeds Rains'}>Reeds Rains</MenuItem>
                <MenuItem value={'Embrace'}>Embrace</MenuItem>
                <MenuItem value={'Other'}>Other</MenuItem>
                <MenuItem value={'PRIMIS - Advanced Mortgage Funding'}>PRIMIS - Advanced Mortgage Funding</MenuItem>
                <MenuItem value={'PRIMIS - Personal Touch Financial Services'}>PRIMIS - Personal Touch Financial Services</MenuItem>
                <MenuItem value={'PRIMIS - First Complete'}>PRIMIS - First Complete</MenuItem>
                <MenuItem value={'The Mortgage Alliance (TMA)'}>The Mortgage Alliance (TMA)</MenuItem>
            </Select>
            <TextField 
              onChange={(e)=>setOtherCompany(e.target.value)} 
              error={otherCompany===''} 
              helperText={otherCompany===''?'Please provide a company/brokerage name':''}
              id="standard-basic"
              className={!getCompany() ? classes.hidden:classes.root} 
              label="Specify company/brokerage" 
              variant='outlined'
            />
            <TextField
              onChange = {(e)=>setFurtherInformation(e.target.value)}
              className={classes.root}
              id="filled-multiline-static"
              label="Further information"
              multiline
              variant='outlined'
            />
            <Button 
            className={classes.root} 
            onClick={()=>submission()}
            variant='outlined'
            >
              Submit
            </Button>



          </div>
        </div>
      </div>
{/*       
              </form>
              <form className='formRight' autoComplete="off">
                <p className='subHead'>Your Details</p>
                <FormControl className='formItems'>
                  <TextField onChange = {(e)=>setColleagueName(e.target.value)}error={colleagueName===''} helperText={colleagueName===''?'Please provide your name':''}id="standard-basic" className={classes.labelContainer} InputProps={{className: classes.labelText}} InputLabelProps={{className:classes.labelText}} label="Your name" />
                  <TextField onChange = {(e)=>setColleagueMail(e.target.value)}error={colleagueMail===''} helperText={colleagueMail===''?'Please provide an email address':''}id="standard-basic" className={classes.labelContainer} InputProps={{className: classes.labelText}} InputLabelProps={{className:classes.labelText}} label="Your email address" />
                  <TextField onChange = {(e)=>setBranch(e.target.value)}id="standard-basic" className={classes.labelContainer} error={branch===''} helperText={branch===''?'Please provide a branch name':''} InputProps={{className: classes.labelText}} InputLabelProps={{className:classes.labelText}} label="Branch" />
                  <TextField type='number' onChange = {(e)=>setBranchPhone(e.target.value)}error={branchPhone===''} helperText={branchPhone===''?'Please provide a branch phone number':''}id="standard-basic" className={classes.labelContainer} InputProps={{className: classes.labelText}} InputLabelProps={{className:classes.labelText}} label="Branch phone no." />
                  <FormHelperText error={company===''} className={classes.labelContainer} InputProps={{className: classes.labelText}} >{company!==''?'Company':'Please select a company'}</FormHelperText>
                  <Select 
                    labelId="selectDropdown"
                    id="selectDropdown"
                    value={company}
                    onChange={handleCompany}
                    className={classes.labelContainer}
                    error={company===''} helperText={company===''?'Please provide a company name':''}
                    >
                      <MenuItem value={'Your Move'}>Your Move</MenuItem>
                      <MenuItem value={'Reeds Rains'}>Reeds Rains</MenuItem>
                      <MenuItem value={'Embrace'}>Embrace</MenuItem>
                      <MenuItem value={'Other'}>Other</MenuItem>
                      <MenuItem value={'PRIMIS - Advanced Mortgage Funding'}>PRIMIS - Advanced Mortgage Funding</MenuItem>
                      <MenuItem value={'PRIMIS - Personal Touch Financial Services'}>PRIMIS - Personal Touch Financial Services</MenuItem>
                      <MenuItem value={'PRIMIS - First Complete'}>PRIMIS - First Complete</MenuItem>
                      <MenuItem value={'The Mortgage Alliance (TMA)'}>The Mortgage Alliance (TMA)</MenuItem>


                  </Select>
                  <TextField onChange = {(e)=>setOtherCompany(e.target.value)} error={otherCompany===''} helperText={otherCompany===''?'Please provide a company/brokerage name':''}id="standard-basic" InputProps={{className: classes.labelText}} InputLabelProps={{className:classes.labelText}} className={!getCompany() ? classes.hidden:classes.labelContainer} label="Specify company/brokerage" />
                  <TextField
                    onChange = {(e)=>setFurtherInformation(e.target.value)}
                    className={classes.labelContainer}
                    id="filled-multiline-static"
                    label="Further information"
                    InputLabelProps={{className:classes.labelText}}
                    multiline
                    rows={4}
                    InputProps={{className: classes.labelText}}
                  />
                </FormControl>
                <Button className={classes.submitButton} onClick={()=>submission()}>Submit</Button>

              </form>
            </div>
          </div>
          <Snackbar open={open} autoHideDuration={6000}>
            <Alert severity="success">Form submitted successfully</Alert>
          </Snackbar>
        </div>
      </div> */}
    </div>
  );
}

export default App;
